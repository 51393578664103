<script lang="ts">
  import './animated-waves.scss';

  export let invertedDirection: boolean;
</script>

<div class:inverted={invertedDirection} class="overflow-hidden">
  <svg
    class="waves"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28"
    preserveAspectRatio="none"
    shape-rendering="auto"
  >
    <defs>
      <path
        id="wave"
        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
      />
    </defs>
    <g class="parallax">
      <use xlink:href="#wave" x="48" y="0" fill="#0f3043" />
      <use xlink:href="#wave" x="48" y="1" fill="#006b79" />
      <use xlink:href="#wave" x="48" y="2" fill="#55ebff" />
      <use xlink:href="#wave" x="48" y="3" fill="#eff066" />
      <use xlink:href="#wave" x="48" y="4" fill="#fbfcff" />
    </g>
  </svg>
</div>
