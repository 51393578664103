<script lang="ts">
  import type { ReferenceCard } from '$lib/builder-components/builder-io.models';
  import ReferenceCardComponent from './ReferenceCard.svelte';

  export let referenceCards: ReferenceCard[] = [];
</script>

<div class="py-6">
  <div class="container">
    <div class="flex flex-col gap-4">
      {#each referenceCards as referenceCard}
        <ReferenceCardComponent data={referenceCard} />
        <!--        <uzn-reference-card [data]="referenceCard"></uzn-reference-card>-->
      {/each}
    </div>
  </div>
</div>
