<script lang="ts">
  import type { ContactItem } from '$lib/builder-components/builder-io.models';

  export let contactItems: ContactItem[] = [];

  const rightAlignedItems = contactItems.filter((item) => item.isRightAligned);
  const leftAlignedItems = contactItems.filter((item) => !item.isRightAligned);
</script>

<div class="light-theme pb-6">
  <div class="container overflow-hidden lg:overflow-visible">
    <div class="grid gap-4 lg:grid-cols-2">
      <div class="flex flex-col items-start gap-4 uzn-fade-items">
        {#each leftAlignedItems as item}
          <a
            class="text-decoration flex items-center gap-4"
            href={item.url}
            target="_blank"
            rel="noopener"
          >
            <img class="icon-32" src={item.icon} alt="Kontakt Icon" />
            {item.title}
          </a>
        {/each}
      </div>
      <div class="flex flex-col items-start gap-4 uzn-fade-items">
        {#each rightAlignedItems as item}
          <a
            class="text-decoration flex items-center gap-4"
            href={item.url}
            target="_blank"
            rel="noopener"
          >
            <img class="icon-32" src={item.icon} alt="Kontakt Icon" />
            {item.title}
          </a>
        {/each}
      </div>
    </div>
  </div>
</div>
