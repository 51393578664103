<script lang="ts">
  export let content = '';
  export let isLightMode = false;
</script>

<div class="dark-theme py-4" class:light-theme={isLightMode}>
  <div class="container">
    <div bind:innerHTML={content} contenteditable="false" />
  </div>
</div>
