export default function main(): void {
  const hiddenElements: HTMLElement[] = Array.from(
    document.querySelectorAll<HTMLElement>("[class*='uzn-fade-']")
  );

  const isInterSectionObserverUnsupported =
    !('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype);

  if (isInterSectionObserverUnsupported) {
    hiddenElements.forEach((element) => element.classList.add('show'));
    return;
  }

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    },
    { threshold: 0 }
  );
  hiddenElements.forEach((element) => observer.observe(element));
}
