<script lang="ts">
  // import { onMount } from 'svelte';
  // onMount(async () => {
  //   await import('@lottiefiles/lottie-player');
  // });
  export let lottieFileUrl: string;
</script>

{#if lottieFileUrl}
  <lottie-player id="lottie" autoplay loop mode="normal" src={lottieFileUrl} style="width: 100%" />
{/if}
