import AnimatedWaves from './AnimatedWaves.svelte';

export default {
  component: AnimatedWaves,
  name: 'Animated Waves Separator',
  inputs: [
    {
      name: 'invertedDirection',
      friendlyName: 'Inverted Direction',
      defaultValue: false,
      type: 'boolean'
    }
  ]
};
