import InteractiveScrollGalleryComponent from '$lib/builder-components/interactive-scroll-gallery/InteractiveScrollGallery.svelte';

export default {
  component: InteractiveScrollGalleryComponent,
  name: 'Interactive Scroll Gallery',
  inputs: [
    {
      name: 'isContentRight',
      friendlyName: 'Show content on right side',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'aspectRatio',
      friendlyName: 'Aspect Ratio of images',
      type: 'number',
      defaultValue: 1
    },
    {
      name: 'isHideImages',
      friendlyName: 'Hide Images On Scroll',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'items',
      friendlyName: 'Items',
      type: 'list',
      subFields: [
        {
          name: 'itemName',
          friendlyName: 'Item Name',
          type: 'string'
        },
        {
          name: 'isNotScreenHeight',
          friendlyName: 'Item not 100vh height',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'content',
          friendlyName: 'Content',
          type: 'richText',
          defaultValue: 'Lorem Ipsum'
        },
        {
          name: 'image',
          friendlyName: 'Image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
        },
        {
          name: 'lottieFile',
          friendlyName: 'Lottie File',
          type: 'file',
          allowedFileTypes: ['json']
        },
        {
          name: 'isButton',
          friendlyName: 'Item is button',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'buttonLabel',
          friendlyName: 'Button label',
          type: 'string',
          defaultValue: 'Download'
        },
        {
          name: 'downloadFile',
          friendlyName: 'Download File',
          type: 'string',
          defaultValue: '/assets/Profil_Suayip_Uzun.pdf'
        }
      ]
    }
  ]
};
