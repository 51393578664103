import HeroBasic from './HeroBasic.svelte';

export default {
  component: HeroBasic,
  name: 'Basic Hero',
  friendlyName: 'Hero',
  inputs: [
    {
      name: 'title',
      friendlyName: 'Title',
      defaultValue: 'Impressum',
      type: 'richText',
      required: true
    }
  ]
};
