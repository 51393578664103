<script lang="ts">
  import type { Technology } from '$lib/builder-components/builder-io.models';

  export let technology: Technology;
</script>

{#if technology.url}
  <a
    href={technology.url}
    class="tooltip"
    rel="noopener"
    target="_blank"
    aria-label={technology.title}
  >
    <span>{technology.title}</span>
    <img class="icon-32" src={technology.icon} alt={technology.title} />
  </a>
{:else}
  <div class="tooltip" aria-label={technology.title}>
    <span>{technology.title}</span>
    <img class="icon-32" src={technology.icon} alt={technology.title} />
  </div>
{/if}
