<script lang="ts">
  import type {
    TechnologyCard,
    TechnologyReference
  } from '$lib/builder-components/builder-io.models';
  import TechnologyItem from '$lib/components/technology-item/TechnologyItem.svelte';
  import TechnologyCardComponent from './TechnologyCard.svelte';

  export let sectionTitle = '';
  export let coreTechnologiesLabel = '';
  export let technologyCards: TechnologyCard[] = [];
  export let coreTechnologies: TechnologyReference[] = [];

  const getTechnologyReference = (technologyReference: TechnologyReference) =>
    technologyReference.technology?.value?.data;

  let oddCards = technologyCards.filter((_, i) => i % 2 === 1);
  let evenCards = technologyCards.filter((_, i) => i % 2 === 0);
</script>

<div class="light-theme py-6">
  <div class="container overflow-hidden lg:overflow-visible">
    <h3>{sectionTitle}</h3>
    {#if coreTechnologies.length > 0}
      <p class="header-6">{coreTechnologiesLabel}</p>
    {/if}
    <div class="flex flex-wrap gap-4 uzn-fade-items">
      {#each coreTechnologies.map(getTechnologyReference) as technology}
        {#if technology}
          <TechnologyItem {technology} />
        {/if}
      {/each}
    </div>

    <div class="grid gap-4 pt-8 lg:grid-cols-2">
      <div class="flex flex-col gap-4 lg:hidden">
        {#each technologyCards as technologyCard}
          <TechnologyCardComponent {technologyCard} />
        {/each}
      </div>

      <div class="hidden flex-col gap-4 lg:flex">
        {#each evenCards as technologyCard}
          <TechnologyCardComponent {technologyCard} />
        {/each}
      </div>

      <div class="hidden flex-col gap-4 pt-32 lg:flex">
        {#each oddCards as technologyCard}
          <TechnologyCardComponent {technologyCard} />
        {/each}
      </div>
    </div>
  </div>
</div>
