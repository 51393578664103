<script lang="ts">
  import type { TechnologyCard } from '$lib/builder-components/builder-io.models';
  import shareIcon from '$lib/media/share.svg';

  export let technologyCard: TechnologyCard;
  const reference = technologyCard.technologyReference?.value.data || technologyCard;
</script>

<div class="uzn-fade-top card flex flex-col gap-4 bg-blue-dark p-6 text-white">
  <div class="pattern-circuit" />
  <div class="flex items-center z-0">
    <img class="icon-32" src={reference.icon} alt={reference.title} />
    <h4 class="m-0 pe-1 ps-4">{reference.title}</h4>
    {#if reference.url}
      <a
        class="button-icon"
        aria-label={reference.title + ' Webseite öffnen'}
        href={reference.url}
        rel="noopener"
        target="_blank"
      >
        <img class="icon-24" src={shareIcon} alt="Teilen Icon" />
      </a>
    {/if}
  </div>
  <div class="z-0" bind:innerHTML={technologyCard.content} contenteditable="false" />
</div>
