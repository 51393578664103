import Padding from './Padding.svelte';

export default {
  component: Padding,
  name: 'Padding',
  inputs: [
    {
      name: 'isLightMode',
      friendlyName: 'Light Theme',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'paddingValue',
      friendlyName: 'Padding Value (pt-4 = 1rem)',
      type: 'string',
      defaultValue: '2rem'
    }
  ]
};
