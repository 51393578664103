import RichTextOnly from './RichTextOnly.svelte';

export default {
  component: RichTextOnly,
  name: 'Rich Text Only',
  friendlyName: 'Hero',
  inputs: [
    {
      name: 'isLightMode',
      friendlyName: 'Light Theme',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'content',
      friendlyName: 'Content',
      type: 'richText',
      required: true
    }
  ]
};
