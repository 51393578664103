<script lang="ts">
  import type { InteractiveScrollGalleryItem } from '$lib/builder-components/builder-io.models';
  import './interactive-scroll-gallery.scss';
  import GalleryItem from './InteractiveScrollGalleryItem.svelte';

  export let items: InteractiveScrollGalleryItem[] = [];
  export let isContentRight = false;
  export let isHideImages = false;
  export let aspectRatio = 1;

  let currentVisibleItemIndex = 0;
  const isLastItem = (index: number) => index === items.length - 1;

  let scrollY: number;
  let innerHeight: number;
  let serviceItems: HTMLElement;

  $: if (scrollY) {
    const children = Array.from(serviceItems?.children || []);
    let latestPassedChild = 0;
    children.forEach((child, index) => {
      const childPosition = child.getBoundingClientRect().top;
      if (childPosition < innerHeight / 1.6) {
        latestPassedChild = index;
      }
    });
    currentVisibleItemIndex = latestPassedChild;
  }
</script>

<svelte:window bind:scrollY bind:innerHeight />

<div class="py-24">
  <div class="container">
    <div class="flex justify-between gap-12 xl:gap-32" class:flex-row-reverse={isContentRight}>
      <div bind:this={serviceItems} class="flex flex-col gap-12 lg:basis-1/2 lg:pb-32 lg:pt-8">
        {#each items as item, i}
          {#if !item.isButton}
            <div class="overflow-hidden lg:overflow-visible flex flex-col gap-12">
              <div class={!isLastItem(i) && !item.isNotScreenHeight ? 'lg:min-h-screen' : ''}>
                <div
                  class:uzn-fade-left={!item.isNotScreenHeight}
                  bind:innerHTML={item.content}
                  contenteditable="false"
                />
              </div>
              <div class="flex justify-center lg:hidden">
                <div class="md:basis-1/2">
                  <GalleryItem {item} {aspectRatio} />
                </div>
              </div>
            </div>
          {/if}
          {#if item.isButton}
            <div class="flex">
              <a
                class="button button-stroked"
                href={item.downloadFile}
                target="_blank"
                rel="noopener"
              >
                {item.buttonLabel}
              </a>
            </div>
          {/if}
        {/each}
      </div>

      <div class="basis-1/2 max-lg:hidden">
        <div class="sticky-item" style="aspect-ratio: {aspectRatio}">
          {#each items as item, i}
            <div class="image" class:hide-image={i !== currentVisibleItemIndex && isHideImages}>
              <GalleryItem {item} {aspectRatio} />
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>
