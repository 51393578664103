import Technologies from './Technologies.svelte';

export default {
  component: Technologies,
  name: 'Technologies',
  inputs: [
    {
      name: 'sectionTitle',
      friendlyName: 'Section Title',
      type: 'string',
      defaultValue: 'Frontend'
    },
    {
      name: 'coreTechnologiesLabel',
      friendlyName: 'Core Technologies Label',
      type: 'string',
      defaultValue: 'Kerntechnologien'
    },
    {
      name: 'coreTechnologies',
      friendlyName: 'Core Technology Icons',
      type: 'list',
      subFields: [
        {
          name: 'title',
          friendlyName: 'Technology Title',
          type: 'string'
        },
        {
          name: 'technology',
          friendlyName: 'Technology',
          type: 'reference',
          model: 'technology'
        }
      ]
    },
    {
      name: 'technologyCards',
      friendlyName: 'Technology Cards',
      type: 'list',
      subFields: [
        {
          name: 'title',
          friendlyName: 'Technology Title',
          type: 'string'
        },
        {
          name: 'url',
          friendlyName: 'Technology Website',
          type: 'url'
        },
        {
          name: 'icon',
          friendlyName: 'Icon',
          type: 'file',
          allowedFileTypes: ['svg']
        },
        {
          name: 'technologyReference',
          friendlyName: 'Technology',
          type: 'reference',
          model: 'technology'
        },
        {
          name: 'content',
          friendlyName: 'Content',
          type: 'richText',
          defaultValue: 'Lorem Ipsum'
        }
      ]
    }
  ]
};
