import LineSeparator from './LineSeparator.svelte';

export default {
  component: LineSeparator,
  name: 'Line Separator',
  inputs: [
    {
      name: 'isLightMode',
      friendlyName: 'Light Theme',
      type: 'boolean',
      defaultValue: false
    }
  ]
};
