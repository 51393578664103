import Contact from './Contact.svelte';

export default {
  component: Contact,
  name: 'Contact',
  inputs: [
    {
      name: 'contactItems',
      friendlyName: 'Contact Item',
      type: 'list',
      subFields: [
        {
          name: 'title',
          friendlyName: 'Contact Title',
          type: 'string'
        },
        {
          name: 'url',
          friendlyName: 'Contact Url',
          type: 'url'
        },
        {
          name: 'icon',
          friendlyName: 'Icon',
          type: 'file',
          allowedFileTypes: ['svg']
        },
        {
          name: 'isRightAligned',
          friendlyName: 'Align Items Right',
          type: 'boolean',
          defaultValue: false
        }
      ]
    }
  ]
};
