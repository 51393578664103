<script lang="ts">
  import { browser } from '$app/environment';
  import { CUSTOM_COMPONENTS } from '$lib/builder-components/custom-components';
  import addIntersectionObserver from '$lib/scripts/intersection-observer';
  import { Content } from '@builder.io/sdk-svelte';
  import { onMount } from 'svelte';
  import type { BuilderPageData } from './+page';
  import { _BUILDER_PUBLIC_API_KEY } from './+page';
  import { navigating } from '$app/stores';

  export let data: BuilderPageData;

  onMount(() => {
    loadIntersectionObserver();
  });

  $: if ($navigating) {
    loadIntersectionObserver();
  }
  const loadIntersectionObserver = () => {
    if (!browser) {
      return;
    }
    // Workaround, because otherwise old page data is loaded
    setTimeout(() => {
      addIntersectionObserver();
    }, 10);
  };
</script>

<svelte:head>
  <title>{data.content?.data?.title}</title>
  <meta name="description" content={data.content?.data?.description} />

  <meta name="og:image" content={data.content?.data?.ogImage} />
  <meta name="og:title" content={data.content?.data?.title} />
  <meta name="og:description" content={data.content?.data?.description} />
</svelte:head>

<section>
  {#if data.canShowContent}
    <Content
      model="page"
      content={data.content}
      enrich={true}
      canTrack={false}
      apiKey={_BUILDER_PUBLIC_API_KEY}
      includeRefs={true}
      customComponents={CUSTOM_COMPONENTS}
    />
  {/if}
</section>
