<script lang="ts">
  import type { InteractiveScrollGalleryItem } from '$lib/builder-components/builder-io.models';
  import LottiePlayer from '$lib/components/lottie-player/LottiePlayer.svelte';

  export let item: InteractiveScrollGalleryItem;
  export let aspectRatio: number;
</script>

{#if item.lottieFile}
  <LottiePlayer lottieFileUrl={item.lottieFile} />
{:else if item.image}
  <img src={item.image} height={300 / aspectRatio} width="300" alt="logo" />
{/if}
