import SectionHeader from './SectionHeader.svelte';

export default {
  component: SectionHeader,
  name: 'Section Header',
  inputs: [
    {
      name: 'isLightMode',
      friendlyName: 'Light Theme',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'isRightAligned',
      friendlyName: 'Align Items Right',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'title',
      friendlyName: 'Title',
      defaultValue: 'Das kann ich für dich tun.',
      type: 'string',
      required: true
    },
    {
      name: 'eyebrow',
      friendlyName: 'Eyebrow',
      defaultValue: 'Leistungen im Überblick',
      type: 'string',
      required: true
    },
    {
      name: 'content',
      friendlyName: 'Content',
      defaultValue: 'Als Senior...',
      type: 'richText',
      required: true
    }
  ]
};
