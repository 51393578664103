<script lang="ts">
  export let isLightMode: boolean;
</script>

<div class="dark-theme py-4" class:light-theme={isLightMode}>
  <div class="container">
    <div class="relative">
      <div class="line bg-blue-regular" />
      <div class="line translate-x-1 translate-y-1 bg-blue-light" />
    </div>
  </div>
</div>

<style lang="scss">
  .line {
    @apply absolute left-0 top-0 w-full;
    height: 2px;
  }
</style>
