<script lang="ts">
  export let title = '';
  export let eyebrow = '';
  export let content = '';
  export let isLightMode = false;
  export let isRightAligned = false;
</script>

<div class="dark-theme py-12" class:light-theme={isLightMode}>
  <div class="container overflow-hidden lg:overflow-visible">
    <div class="flex flex-col" class:lg:items-end={isRightAligned}>
      <p class="uzn-fade-left header-5">
        {eyebrow}
      </p>
      <h2 class="uzn-fade-left">{title}</h2>
      <div class="uzn-fade-right pt-8" bind:innerHTML={content} contenteditable="false" />
    </div>
  </div>
</div>
