<script lang="ts">
  import type { ReferenceCard } from '$lib/builder-components/builder-io.models';
  import TechnologyItem from '$lib/components/technology-item/TechnologyItem.svelte';
  import icon from '$lib/media/plus.svg';
  import './reference-card.scss';

  export let data: ReferenceCard;
  let isExpanded = false;

  function toggleExpanded() {
    isExpanded = !isExpanded;
  }
</script>

<div class="light-theme card invert-colors flex flex-col bg-white p-6">
  <div>
    <h3>{data.referenceTitle}</h3>
  </div>

  <div bind:innerHTML={data.content} contenteditable="false" />

  <div class="flex flex-col justify-between gap-6 pt-8 lg:flex-row-reverse lg:items-end">
    <div class="flex flex-col lg:items-end">
      <p class="header-6">Verwendete Technologien</p>
      <div class="flex gap-4">
        {#each data.coreTechnologies.map((item) => item.technology?.value?.data) as technology}
          <TechnologyItem {technology} />
        {/each}
      </div>
    </div>

    <div class="dark-theme">
      <button on:click={toggleExpanded} class="button button-flat dark">
        <img class="animate-icon" src={icon} alt="Plus Icon" class:expanded={isExpanded} />
        Projektdetails
      </button>
    </div>
  </div>

  <div class="expand-details" class:expanded={isExpanded}>
    <div class="pt-8" bind:innerHTML={data.details} contenteditable="false" />
  </div>
</div>
