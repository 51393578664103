import AnchorComponent from '$lib/builder-components/anchor/anchor';
import AnimatedWavesComponent from '$lib/builder-components/animated-waves/animated-waves';
import ContactComponent from '$lib/builder-components/contact/contact';
import HeroBasicComponent from '$lib/builder-components/hero-basic/hero-basic';
import HeroComponent from '$lib/builder-components/hero/hero';
import InteractiveScrollGalleryComponent from '$lib/builder-components/interactive-scroll-gallery/interactive-scroll-gallery';
import LineSeparatorComponent from '$lib/builder-components/line-separator/line-separator';
import PaddingComponent from '$lib/builder-components/padding/padding';
import ReferencesComponent from '$lib/builder-components/references/references';
import RichTextOnlyComponent from '$lib/builder-components/rich-text-only/rich-text-only';
import SectionHeaderComponent from '$lib/builder-components/section-header/section-header';
import TechnologiesComponent from '$lib/builder-components/technologies/technologies';

export const CUSTOM_COMPONENTS = [
  AnchorComponent,
  AnimatedWavesComponent,
  ContactComponent,
  HeroBasicComponent,
  HeroComponent,
  InteractiveScrollGalleryComponent,
  LineSeparatorComponent,
  PaddingComponent,
  ReferencesComponent,
  RichTextOnlyComponent,
  SectionHeaderComponent,
  TechnologiesComponent
];
