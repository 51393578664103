import Anchor from './Anchor.svelte';

export default {
  component: Anchor,
  name: 'Anchor',
  inputs: [
    {
      name: 'anchor',
      friendlyName: 'Anchor name',
      defaultValue: 'ueber-mich',
      type: 'string',
      required: true
    }
  ]
};
