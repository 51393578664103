<script lang="ts">
  import './hero.scss';
  import LottiePlayer from '$lib/components/lottie-player/LottiePlayer.svelte';
  import type { ContactItem } from '../builder-io.models';

  export let title = '';
  export let subtitle = '';
  export let lottieFileUrl: string;
  export let contactItems: ContactItem[] = [];
</script>

<div class="flex flex-col justify-center">
  <div class="container py-28">
    <div
      class="overflow-hidden lg:overflow-visible relative flex items-center justify-between max-lg:flex-col max-lg:gap-12"
    >
      <div class="lg:basis-1/2">
        <div class="uzn-fade-right" bind:innerHTML={title} contenteditable="false" />
        <div class="uzn-fade-right pt-8" bind:innerHTML={subtitle} contenteditable="false" />
      </div>
      <div class="uzn-fade-top lg:basis-1/2 aspect-square">
        <LottiePlayer {lottieFileUrl} />
      </div>

      <div class="contact-items hidden lg:block">
        <div class="flex flex-col gap-4">
          {#each contactItems as item, i}
            <div class="item-{i}">
              <div>
                <a href={item.url} aria-label={item.title} target="_blank" rel="noopener">
                  <img class="icon-48" src={item.icon} alt="Kontakt Icon" />
                </a>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>
