import Hero from './Hero.svelte';

export default {
  component: Hero,
  name: 'Hero Component',
  friendlyName: 'Hero',
  inputs: [
    {
      name: 'title',
      friendlyName: 'Title',
      defaultValue: 'Experte für...',
      type: 'richText',
      required: true
    },
    {
      name: 'subtitle',
      friendlyName: 'Subtitle',
      defaultValue: 'Suayip Uzun',
      type: 'richText',
      required: true
    },
    {
      name: 'lottieFileUrl',
      friendlyName: 'Lottie Animation',
      type: 'file',
      allowedFileTypes: ['json'],
      required: true
    },
    {
      name: 'contactItems',
      friendlyName: 'Contact Item',
      type: 'list',
      subFields: [
        {
          name: 'title',
          friendlyName: 'Contact Title',
          type: 'string'
        },
        {
          name: 'url',
          friendlyName: 'Contact Url',
          type: 'url'
        },
        {
          name: 'icon',
          friendlyName: 'Icon',
          type: 'file',
          allowedFileTypes: ['svg']
        }
      ]
    }
  ]
};
