import References from './References.svelte';

export default {
  component: References,
  name: 'References',
  inputs: [
    {
      name: 'referenceCards',
      friendlyName: 'Reference Cards',
      type: 'list',
      subFields: [
        {
          name: 'referenceTitle',
          friendlyName: 'Reference Title',
          type: 'string',
          defaultValue: 'CRM-System für...'
        },
        {
          name: 'referenceUrl',
          friendlyName: 'Technology Website',
          type: 'url'
        },
        {
          name: 'content',
          friendlyName: 'Content',
          type: 'richText',
          defaultValue: 'Lorem Ipsum'
        },
        {
          name: 'details',
          friendlyName: 'More Details',
          type: 'richText',
          defaultValue: 'Lorem Ipsum'
        },
        {
          name: 'coreTechnologies',
          friendlyName: 'Core Technologies',
          type: 'list',
          subFields: [
            {
              name: 'technology',
              friendlyName: 'Technology',
              type: 'reference',
              model: 'technology'
            }
          ]
        }
      ]
    }
  ]
};
